



























































import { defineComponent } from '@vue/composition-api';
import { ActivitiesTableSorted, ActivitiesPageUpdated } from './+state';
import { ActivitiesSortCol, SortDir } from '@/shared';
import sink from '@/sink';

export default defineComponent({
  setup() {
    const sortCol = sink.select('activities.sort-col');
    const sortDir = sink.select('activities.sort-dir');

    const updateSort = (sortInfo: { col: ActivitiesSortCol; dir: SortDir }) =>
      sink.broadcast(ActivitiesTableSorted(sortInfo));

    const onChangeSortColumn = (col: ActivitiesSortCol) => {
      updateSort({ col, dir: sortDir.value });
    };

    const onChangeSortDirection = (isDesc: boolean) => {
      updateSort({ col: sortCol.value, dir: isDesc ? 'desc' : 'asc' });
    };

    return {
      onChangeSortColumn,
      onChangeSortDirection,
      updatePage: page => {
        sink.broadcast(ActivitiesPageUpdated({ page }));
      },
      sortCol,
      sortDir,
      activities: sink.select('activities.table'),
      count: sink.select('activities.count'),
      filtersDirty: sink.select('activities.filters-dirty'),
      pageNumber: sink.select('activities.page-number'),
      tableMessage: sink.select('activities.tableMessage'),
      headers: [
        { value: 'activityDate', text: 'Activity Date', sortable: true },
        {
          value: 'patientName',
          text: 'Patient',
          sortable: true,
        },
        {
          value: 'providerName',
          text: 'Provider',
          sortable: true,
        },
        { value: 'activity', text: 'Activity', sortable: false },
      ],
    };
  },
});
