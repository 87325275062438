
















































































import {
  ListPageFilters,
  PatientStatusFilter,
  ActivityFilter,
  MultiSelect,
  InlineLoader,
} from '@/shared';
import DatePicker from '@/shared/components/DatePicker.vue';
import sink from '@/sink';
import { computed, defineComponent } from '@vue/composition-api';
import {
  ActivitiesFilterChanged,
  ActivityFiltersCleared,
  ActivitiesFilterSearched,
  ActivitiesFiltersRestored,
} from './+state/events';
import { ActivitiesFilters } from './models';

export default defineComponent({
  components: {
    ActivityFilter,
    DatePicker,
    InlineLoader,
    ListPageFilters,
    PatientStatusFilter,
    MultiSelect,
  },
  setup() {
    const filters = sink.select('activities.filters');
    sink.broadcast(ActivitiesFiltersRestored());

    return {
      filters,
      loadingProviders: sink.select('providers.loading'),
      loadingActivities: sink.select('activities.loading'),
      providers: sink.select('providers.select-options'),
      isDateRangeValid: computed(() => {
        if (filters.value.activityDateFrom && filters.value.activityDateTo) {
          return (
            new Date(filters.value.activityDateTo) >=
              new Date(filters.value.activityDateFrom) ||
            'Activity Date From must be on/before Activity Date To'
          );
        }
        return true;
      }),
      onSearch: sink.lazyBroadcast(
        ActivitiesFilterSearched({ origin: 'search', resetPage: true }),
      ),
      onClearAll: sink.lazyBroadcast(ActivityFiltersCleared()),
      onInputChanged(input: keyof ActivitiesFilters, value) {
        sink.broadcast(ActivitiesFilterChanged({ input, value }));
      },
    };
  },
});
